// 临加学员
<template>
  <div class="look_lesson_dialog">
    <CommonDialog @close="close" :showDialog="showDialog" :title="title" :width="width">
      <template v-slot:content>
        <div class="dialog_content">
          <!-- 内容视图 -->
          <div class="dv_table_view_content" v-if="showLoading == 1">
            <el-table style="width: 98%; margin: auto" @selection-change="handleSelectionChange" border stripe
              :data="tableData" max-height="280">
              <!-- 选中按钮 -->
              <el-table-column type="selection" width="65"> </el-table-column>
              <!-- 序号 -->
              <el-table-column type="index" label="序号" align="center" width="80">
                <template scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <!-- 姓名 -->
              <el-table-column prop="name" :show-overflow-tooltip="true" label="姓名" width="140"></el-table-column>

              <!-- 学生ID  -->
              <el-table-column prop="id" :show-overflow-tooltip="true" label="学生ID" width="120"></el-table-column>

              <!-- 手机号  -->
              <el-table-column prop="tel" :show-overflow-tooltip="true" label="手机号" width="140"></el-table-column>

              <!-- 预报名课程  -->
              <el-table-column min-width="30%" prop="sub_name" label="预报名课程"></el-table-column>
            </el-table>

            <!-- 底部 -->
            <div class="bom_page margin_bom_20">
              <!-- 底部的取消和保存按钮 -->
              <div class="bom">
                <!-- 取消 com_bt_action -->
                <el-button size="medium" class="bt_cancel" @click.stop="doCancel">取&nbsp;&nbsp;消</el-button>

                <!-- 保存 -->
                <el-button size="medium" class="bt_next" type="primary" :loading="loading" :disabled="SelectLength == 0"
                  @click.stop="doSave">保&nbsp;&nbsp;存</el-button>
              </div>
            </div>
          </div>

          <!-- 加载视图 -->
          <!-- <Loading v-if="showLoading == -1"></Loading> -->
          <div class="studialog_content" v-if="isShowLoading">
            <!-- 标题 -->
            <div class="top">
              <span class="com_title">提示</span>
              <!-- <i class="iconfont cloud-guanbi" @click.stop="close"></i> -->
              <i class="el-icon-close" style="margin-right: 7px" @click.stop="closeDialog"></i>
            </div>
            <!-- 间隔线 -->
            <el-divider></el-divider>

            <!-- 提示信息部分 -->
            <div class="msg_info" v-show="SelectLength > 1">
              确认将学员：{{ multipleSelection[0].name }}等{{
                SelectLength
              }}人加入班级么？


            </div>
            <div class="tip_text" v-show="SelectLength > 1">注：临听学员</div>
            <div class="msg_info" v-show="SelectLength == 1">
              确认将学员：{{ multipleSelection[0].name }}加入班级么？

            </div>
            <div class="tip_text" v-show="(SelectLength == 1)">注：临听学员</div>
            <!-- 底部 -->
            <div class="com_dv margin_top_10">
              <!-- 底部的取消和保存按钮 -->
              <div class="bom margin_top_10">
                <!-- 取消 com_bt_action -->
                <el-button size="medium" class="commit_bt" @click.stop="closeDialog">取&nbsp;&nbsp;消</el-button>

                <!-- 保存 -->
                <el-button size="medium" class="commit_bt" type="primary" :loading="loadingtemp"
                  @click.stop="doSaveAction">保&nbsp;&nbsp;存</el-button>
              </div>
            </div>
          </div>
        </div>


      </template>
    </CommonDialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      loadingtemp: false,
      //选中个数
      SelectLength: 0,
      //选中的数组
      multipleSelection: [],
      //显示加载布局的标识
      showLoading: 1,

      //表格的最大高度
      maxHeight: 0,

      //表格数据
      tableData: [],

      dialogTitle: "提示",

      isCenter: false,

      dialogWidth: "400px",

      //是否显示loading
      isShowLoading: false,
      //加入学生的id
      ids: "",
    };
  },
  created() { },
  mounted() {
    console.log(this.item);
    this.httpData();
  },
  methods: {
    //关闭的方法
    closeDialog() {
      this.isShowLoading = false;
    },

    //确定添加
    doSaveAction() {
      //连点屏蔽
      // if(!this.isQuickClick()){

      if (this.loadingtemp) {

        return;
      }
      this.ids = "",
        this.multipleSelection.forEach((element) => {
          this.ids += element.id + "-";
        });
      this.ids = this.ids.slice(0, this.ids.length - 1)
      console.log(this.ids);

      this.loadingtemp = true;
      this.addStudentHttp();

      // }
    },
    /**
     *  临加学员列表接口
     */
    httpData() {
      let param = this.getHttpParams();
      param.type = 11;
      param.cid = this.item.class_id;
      // param.cid = "1800045";

      param.sign = "0"; //标记（0 临时， 1 正式）

      this.$http
        .fetchPost(this.$api.CLASSMANAGER, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let resulte = res.data;
          if (resulte.state == 200) {
            //获取列表数据
            this.tableData = resulte.data.infoList;
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },

    /**
     *  添加临时学员接口
     */
    addStudentHttp() {
      let param = this.getHttpParams();
      param.type = 12;
      param.cid = this.item.class_id;
      param.sign = "0"; //标记（0 临时， 1 正式）
      param.ids = this.ids;

      this.$http
        .fetchPost(this.$api.CLASSMANAGER, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          this.loadingtemp = false;
          let resulte = res.data;
          if (resulte.state == 200) {
            this.showSuccessMsg("添加成功");
            this.$emit("close");
            this.$emit("reflash");
          } else if (resulte.state == 406) {

            this.showWarnMsg("与课程" + resulte.data.class_name + "的" + resulte.data.time_range + "时段冲突");

          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.loadingtemp = false;
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },

    //选中的回调
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.SelectLength = this.multipleSelection.length;
    },
    //关闭的方法
    close() {
      this.$emit("close");
      this.isShowLoading = false;
    },

    //保存按钮打开弹窗
    doSave() {
      console.log(this.multipleSelection);
      if (this.SelectLength != 0) {
        this.isShowLoading = true;
      } else {
        this.showWarnMsg("请选择学生");
      }
    },

    //取消
    doCancel() {
      this.$emit("close");
      this.isShowLoading = false;
    },
  },
  computed: {},
  components: {},

  props: ["showDialog", "title", "width", "item", "courseId"],
};
</script>
<style lang="scss" scoped>
.look_lesson_dialog {
  .studialog_content {
    position: absolute;
    min-height: 220px;
    min-width: 500px;
    margin-left: 20%;

    background: #efefef;
    border-radius: 6px;

    border-width: 3px;

    .top {
      display: flex;
      margin: 12px 0;
      // height: 58px;
      align-items: center;
      position: relative;

      .com_title {
        color: black;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 14px;
        font-size: 16px;
        font-weight: bold;
      }

      .cloud-guanbi {
        position: absolute;
        cursor: pointer;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .com_dv {
      width: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    .msg_info {
      margin-top: 45px;
      display: flex;
      font-weight: bold;
      justify-content: center;
      align-items: center;

    }

    .tip_text {
      font-size: 12px;
      margin-top: 20px;
      margin-left: 95px;
    }

    .commit_bt {
      height: 35px;
      width: 120px;
      // position: absolute;
      // bottom: 14px;
    }
  }

  .dialog_content {
    height: 60vh;
    margin: 10px 0;
    display: flex;
    align-items: center;

    //表格内容部分
    .dv_table_view_content {
      width: 97%;
      margin: 0 auto;
      height: 100%;

      .input_item {
        width: 188px;
      }

      //底部的分页器部分
      .bom_page {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        margin-top: 18px;
        display: flex;

        height: 40px;

        .text {
          margin-left: 10%;
          margin-top: -12px;
        }

        .bom {
          position: absolute;
          height: 40px;
          bottom: 20px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .bt_cancel,
          .bt_next {
            width: 120px;
          }

          .bt_next {
            margin-left: 100px;
          }
        }
      }
    }
  }
}
</style>