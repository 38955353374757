// 排课信息
<template>
  <div class="course_arrangement">
    <!-- 班级表格部分 -->
    <div class="margin_top_10">
      <span>时间：</span>
      <el-date-picker class="margin_left_4" v-model="selectDate" type="daterange" range-separator="至" size="small"
        start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions"
        @change="clickDate()">
      </el-date-picker>

      <span class="margin_left_40"> 已排课：{{ planNum }}</span>
      <span class="margin_left_40"> 已上课：{{ onNum }}</span>
    </div>
    <!-- 学员表格部分 -->
    <div class="dv_table_view_content">
      <el-table style="width: 100%; margin: auto" border stripe :data="tableData" :max-height="maxHeight">
        <!-- 序号 -->
        <el-table-column type="index" label="序号" align="center" width="100">
          <template scope="scope">
            <span>{{ (currPage - 1) * pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>

        <!-- 上课时间 -->
        <el-table-column prop="class_date" label="上课时间/星期" width="220">
          <template slot-scope="scope"> {{ scope.row.class_date }}/{{ scope.row.weekDay }} </template>
        </el-table-column>

        <!-- 上课时段 -->
        <el-table-column prop="time_range" label="上课时段" width="200"></el-table-column>

        <!-- 教室  -->
        <el-table-column prop="room_name" label="教室" width="160"></el-table-column>

        <!-- 上课状态   （-1已取消，0未上，1已上） -->
        <el-table-column prop="totalNum" label="上课状态" width="160">
          <template slot-scope="scope">
            {{ scope.row.class_state == -1 ? "已取消" : scope.row.class_state == 0 ? "未上" : "已上" }}
          </template>
        </el-table-column>

        <!-- 上课老师 -->
        <el-table-column prop="teacher_name" label="上课老师" width="180"></el-table-column>

        <!-- 请假未到学员 -->
        <el-table-column prop="totalNum" label="请假学员" width="160">
          <template slot-scope="scope">
      
            <span :class="[item.state==0 ? 'student_finish' : 'student_miss']"
              v-for="(item, index) in scope.row.unattended" :key="index">
              <span @click="doLookStudent(item)">{{item.name}}</span>

            </span>
            <span v-if="scope.row.unattended.length == 0">--</span>
          </template>
        </el-table-column>

        <!--  操作 v-if="role != '3'"  -->
        <el-table-column label="操作" min-width="10%">
          <template slot-scope="scope">
            <div v-if="scope.row.class_state == 0">
              <el-button :disabled="role != 3" @click="changeCourse(scope.row)" type="text" size="small">调课</el-button>
              <el-button :disabled="role != 3" @click="cancelCourse(scope.row)" type="text" size="small">取消</el-button>
            </div>
            <div v-if="scope.row.class_state != 0">--</div>
          </template>
        </el-table-column>
      </el-table>

      <!-- 顶部的分页器部分 -->
      <div class="bom_page margin_bom_10" v-if="tableData && tableData.length > 0">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="currPage" :page-sizes="pageSizeArr" :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="totalSize">
        </el-pagination>
      </div>
    </div>
    <!-- 调课 -->
    <ChangeCourse v-if="showDialog" @close="showDialog = false" title="调课" :showDialog="showDialog"
      :width="changeCourseWidth" :item="item" :planId="planId" @reflash="httpData"></ChangeCourse>

    <!-- 取消课程的弹框 -->
    <RemoveCourseDialog ref="del_item" v-if="showDelDialog" :showDialog="showDelDialog" @close="showDelDialog = false"
      :title="delTitle" :item="courseItem" @delItem="delItem"></RemoveCourseDialog>
  </div>
</template>
<script>
// 正式
import ChangeCourse from "@/views/educationalcenter/classmanager/ChangeCourse";
//取消课程
import RemoveCourseDialog from "@/components/project/RemoveCourseDialog";
export default {
  data() {
    return {
      //账号角色
      role: "",

      //排课ID
      planId: "",

      //已排课
      planNum: "",

      //已上课
      onNum: "",

      //标题
      delTitle: "取消课程",

      //调课
      changeCourseWidth: "700px",

      //取消课程
      showDelDialog: false,

      //调课
      showDialog: false,

      //筛选日期
      selectDate: [],

      //表格的最大高度
      maxHeight: 0,

      //总的数据条数
      totalSize: 30,

      //条数选择的数据
      pageSizeArr: [6, 8, 10],

      //每页显示的条数
      pageSize: 10,

      //当前显示的页码
      currPage: 1,

      //表格数据
      tableData: [],

      //日期范围
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24 * 180 || time.getTime() > Date.now() + 3600 * 1000 * 24 * 180;
        },
      },

      //操作的条目
      courseItem: null,
    };
  },
  created() {
    //初始化数据
    this.initData();
  },
  mounted() {
    this.selectDate.push(this.getWeekDataList()[0]);
    this.selectDate.push(this.getWeekDataList()[6]);
    //请求数据
    this.httpData();
  },
  methods: {
    //初始化表格数据
    initData() {
      let clientH = document.documentElement.clientHeight;
      this.maxHeight = clientH - 76 - -54 - 20 - 68 - 220;

      //-1游客 0学生  1老师 2校区教务 3校长 4管理员 5超级管理员
      this.role = this.getUserInfo().role;
    },

    /**
     *  排课信息列表接口
     */
    httpData() {
      let param = this.getHttpParams();
      param.type = 13;
      param.cid = this.item.class_id;
      param.sDate = this.selectDate[0];
      param.eDate = this.selectDate[1];
      param.page = this.currPage - 1;
      param.pagecount = this.pageSize;

      this.$http
        .fetchPost(this.$api.CLASSMANAGER, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let resulte = res.data;
          if (resulte.state == 200) {
            //已排
            this.planNum = resulte.data.planNum;
            //已上
            this.onNum = resulte.data.onNum;
            //获取总条数
            this.totalSize = Number.parseInt(resulte.data.totalNum);
            //列表
            this.tableData = resulte.data.infoList;
            if (this.totalSize == 0) {
              // this.showWarnMsg("暂无排课数据");
            }
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },

    getWeekDataList() {
      let oneDayTime = 1000 * 60 * 60 * 24;
      let today = new Date();
      let todayDay = today.getDay() || 7;
      let startDate = new Date(today.getTime() - oneDayTime * (todayDay - 1));
      let dateList = [];
      for (let i = 0; i < 7; i++) {
        let temp = new Date(startDate.getTime() + i * oneDayTime);
        let year = temp.getFullYear();
        let month = temp.getMonth() + 1 < 10 ? "0" + (temp.getMonth() + 1) : temp.getMonth() + 1;
        let day = temp.getDate() < 10 ? "0" + temp.getDate() : temp.getDate();
        dateList[i] = `${year}-${month}-${day}`;
      }
      return dateList;
    },
    //点击日期
    clickDate() {
      this.httpData();
    },
    //调课
    changeCourse(item) {
      this.courseItem = item;
      this.planId = item.id;
      this.showDialog = true;
    },

    //取消课程
    cancelCourse(item) {
      this.courseItem = item;
      this.showDelDialog = true;
    },

    //取消课程
    delItem() {
      let param = this.getHttpParams();
      param.type = 16;
      param.cid = this.item.class_id;
      param.planId = this.courseItem.id;

      this.$http
        .fetchPost(this.$api.CLASSMANAGER, param)
        .then((res) => {
          //显示内容视图
          let resulte = res.data;
          if (resulte.state == 200) {
            this.showSuccessMsg("操作成功");
            this.httpData();
            this.showDelDialog = false;
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },

    //每页显示的条数变化时触发
    handleSizeChange(val) {
      this.pageSize = val;
      // console.log("当前页显示的条数是:", this.pageSize);
      //重新刷新数据
      this.httpData();
    },

    //页面改变时触发
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currPage = val;
      // console.log("当前页码是:", this.currPage);
      //重新刷新数据
      this.httpData();
    },

    //跳转学员列表
     doLookStudent(item) {
      // 获取学生数据
      this.getStudentHttpData(item);
      //跳转到学生档案
    },

    // 获取学生数据
    getStudentHttpData(item) {
      let param = this.getHttpParams();
      param.type = 17;
      param.student_id = item.id;
      // param.student_id = "3003365";

      this.$http
        .fetchPost(this.$api.StudentManageController, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let resulte = res.data;
          if (resulte.state == 200) {
            this.$router.push({
              path: "/Home/StuRecord/StudentInfo",
              query: { userInfo: JSON.stringify(resulte.data) },
            });
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },
  },
  props: ["item"],
  computed: {},
  components: { ChangeCourse, RemoveCourseDialog },
};
</script>
<style lang="scss" scoped>
.course_arrangement {
  height: 100%;

  //表格内容部分
  .dv_table_view_content {

    //底部的分页器部分
    .bom_page {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      margin-top: 10px;
      display: flex;
      align-items: center;
      height: 40px;
      justify-content: center;
    }

    .student_finish {
      color: rgb(16, 216, 93);
      margin-right: 5px;
      cursor: pointer;
    }

    .student_miss {
      color: rgba(248, 165, 11, 0.918);
      margin-right: 5px;
      cursor: pointer;
    }
  }
}
</style>
