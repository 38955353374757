//查看班级详情
<template>
  <div class="class_detail">
    <Layout :showLoading="showLoading">
      <template v-slot:content>
        <div class="class_detail_content">
          <!-- 教师姓名手机号 -->
          <div class="margin_top_20">
            <span>班级名称:{{ item.class_name }}</span>
            <span class="margin_left_20">班级ID:{{ item.class_id }}</span>
            <span class="margin_left_20">班级类型:{{ item.type == 1 ? "正式课" : "试听课" }}</span
            ><!--班级类型（1正常班级 0试听班级） -->
            <span class="margin_left_20"
              >课程名称:
              <span v-if="item.subList==null ||item.subList.length==0">暂无课程权限</span>
              <el-select v-if="item.subList!=null" v-model="courseId" placeholder="请选择" size="small">
                <el-option
                  v-for="(item, index) in item.subList"
                  :key="item.subId"
                  :label="item.subName"
                  :value="item.subId"
                  @click.native="changeCourse1(index)"
                >
                </el-option>
              </el-select>
            </span>

            <!-- <span class="margin_left_20"
              >课程级别:

              <el-select v-model="levelId" placeholder="请选择" size="small">
                <el-option
                  v-for="(item, index) in levelList"
                  :key="item.levelId"
                  :label="item.levelName"
                  :value="item.levelId"
                  @click.native="changeCourse2(index)"
                >
                </el-option>
              </el-select>
            </span>

            <span class="margin_left_20"
              >主题名称:

              <el-select v-model="lessonId" placeholder="请选择" size="small">
                <el-option v-for="item in lessonList" :key="item.lessonId" :label="item.lessonName" :value="item.lessonId"> </el-option>
              </el-select>
            </span> -->
            <!-- <span class="margin_left_30">班级老师:{{ item.teacher_name }}</span> -->
            <div class="margin_top_10">
              实招/预招:{{ item.class_num }}/{{ item.plan_num }}

              <span class="margin_left_30">是否限制入班人数:{{ item.limit_plan == 1 ? "是" : "否" }}</span>
            </div>
          </div>

          <!-- 切换按钮 -->
          <div class="action">
            <div class="margin_top_20">
              <el-tabs v-model="radio" @tab-click="doAction">
                <el-tab-pane label="学员信息" name="1"></el-tab-pane>
                <el-tab-pane label="排课信息" name="2"></el-tab-pane>
                <!-- <el-tab-pane label="考勤与评价" name="3"></el-tab-pane> -->
              </el-tabs>
            </div>
          </div>

          <!-- 学员列表 -->
          <ClassStudentList v-show="showClassPage == 0" :item="item" @reflash="httpData"></ClassStudentList>

          <!-- 排课信息 -->
          <CourseArrangement v-show="showClassPage == 1" :item="item"></CourseArrangement>

          <!-- 考勤与评价 -->
          <AttendanceEvaluation v-show="showClassPage == 2" :item="item" :courseId="courseId"></AttendanceEvaluation>
        </div>
      </template>
    </Layout>
  </div>
</template>
<script>
import ClassStudentList from "@/views/educationalcenter/classmanager/ClassStudentList";

import CourseArrangement from "@/views/educationalcenter/classmanager/CourseArrangement";

import AttendanceEvaluation from "@/views/educationalcenter/classmanager/AttendanceEvaluation";

export default {
  data() {
    return {
      levelList: [],
      lessonList: [],
      //选中课程ID
      courseId: "",
      //级别课程ID
      levelId: "",
      //课次ID
      lessonId: "",

      //数据
      item: {},

      //切换页面
      showClassPage: 0,
      radio: "1",

      //是否显示加载布局
      showLoading: 1,

      //查看课程的弹框宽度
      LookClassDialogWidth: "800px",
    };
  },
  created() {
    //班级ID
    this.item = JSON.parse(this.$route.query.item);
  },
  mounted() {
    this.httpData();
  },
  methods: {
    //选中课程的回调
    changeCourse1(val) {
      console.log(val);
      this.levelList = this.item.subList[val].levelList;
      this.levelId = "";
      this.lessonId = "";
    },
    //选中级别的回调
    changeCourse2(val) {
      console.log(val);
      this.lessonList = this.levelList[val].lessonList;
      this.lessonId = "";
    },

    //获取班级信息
    httpData() {
      let param = this.getHttpParams();
      param.type = 5;
      param.cid = this.item.class_id;

      this.$http
        .fetchPost(this.$api.CLASSMANAGER, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let resulte = res.data;
          if (resulte.state == 200) {
            //获取列表数据
            this.item = resulte.data;

            this.courseId = this.item.subList[0].subId;

            this.levelList = this.item.subList[0].levelList;
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },

    //标签栏切换
    doAction(tab, event) {
      this.showClassPage = tab.index;
    },
  },
  computed: {},
  components: {
    ClassStudentList,
    CourseArrangement,
    AttendanceEvaluation,
  },
};
</script>
<style lang="scss" scoped>
.class_detail {
  height: calc(100vh - 77px);
  margin: 0 auto;
  width: 98%;

  .class_detail_content {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    ::v-deep .el-table .cell {
      text-align: center;
    }

    //搜索部分
    .action {
      height: 54px;
      display: flex;
      align-items: center;
      min-width: 1100px;
      ::v-deep .el-tabs__active-bar {
        background-color: transparent !important;
        line-height: 10px;
      }
      ::v-deep.el-tabs__nav-wrap::after {
        position: static !important;
      }

      ::v-deep .el-input__inner {
        height: 32px;
        line-height: 32px;
        padding-left: 6px;
      }

      ::v-deep .el-input__icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ::v-deep .el-input {
        width: 210px;
      }

      ::v-deep .el-input__suffix {
        right: 0px;
      }

      .dv_time {
        display: flex;
        align-items: center;
        ::v-deep .el-range-editor.el-input__inner {
          display: flex;
          align-items: center;
          padding: 0 6px;
          width: 320px;
        }
        ::v-deep {
          .el-date-editor .el-range-input {
            width: 120px;
          }
        }
      }
    }
  }
}
</style>
