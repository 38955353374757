// 学员信息
<template>
  <div class="course_arrangement">
    <div class="temp_student">
      <!-- 正式学员 -->
      <el-button v-if="!isFormal && isAddCtrl" class="margin_right_10" @click="formalStudent" type="primary" size="medium">+正式课学员</el-button>
      <!-- 临加学员 -->
      <el-button v-if="isFormal && isAddCtrl" @click="tempStudent" type="primary" size="medium">+试听课学员</el-button>
    </div>
    <!-- 临加学员 -->

    <!-- 学员表格部分 -->
    <div class="dv_table_view_content">
      <el-table style="width: 100%; margin: auto" border stripe :data="tableData" :max-height="maxHeight">
        <!-- 序号 -->
        <el-table-column type="index" label="序号" align="center" width="50">
          <template scope="scope">
            <span>{{ (currPage - 1) * pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>

        <!-- 姓名 -->
        <el-table-column label="姓名" width="160">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.join_state == -1" class="tempIcon">临加</span>
              <span>{{ scope.row.name }}</span>
            </div>
          </template></el-table-column
        >
        <!-- 年龄 -->
        <el-table-column prop="years" label="年龄" width="90"></el-table-column>

        <!-- 学生id -->
        <el-table-column prop="id" label="学生ID" width="140"></el-table-column>

        <!-- 学生手机号  -->
        <el-table-column prop="tel" :show-overflow-tooltip="true" label="手机号" width="190"></el-table-column>

        <!-- 剩余课时/总课时 -->
        <el-table-column prop="balance_hour,total_hour" label="剩余课时/总课时" width="160">
          <template slot-scope="scope"> {{ scope.row.balance_hour }}/{{ scope.row.total_hour }} </template>
        </el-table-column>

        <!--实际出勤/应出勤次数 -->
        <el-table-column prop="attend_num,arrange_num" label="实际出勤/应出勤次数" width="160">
          <template slot-scope="scope"> {{ scope.row.attend_num }}/{{ scope.row.arrange_num }} </template>
        </el-table-column>

        <!--  操作 -->
        <el-table-column label="操作" min-width="20%">
          <template slot-scope="scope">
            <div>
              <el-button @click="doLookStudent(scope.row)" type="text" size="small">查看</el-button>
              <!--  v-if="role == '3'" -->
              <el-button v-if="isAddCtrl" @click="delStudent(scope.row)" type="text" size="small">移除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- 顶部的分页器部分 -->
      <div class="bom_page margin_bom_10" v-if="tableData && tableData.length > 0">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-sizes="pageSizeArr"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalSize"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 临加学员 -->
    <TempStudentList
      v-if="showDialog"
      @close="showDialog = false"
      title="请选择试听课学员"
      :showDialog="showDialog"
      :width="TempStudentListWidth"
      :item="item"
      @reflash="reflash"
    ></TempStudentList>

    <!-- 正式学员 -->
    <FormalStudentList
      v-if="showformalDialog"
      @close="showformalDialog = false"
      title="请选择正式学员"
      :showDialog="showformalDialog"
      :width="TempStudentListWidth"
      :item="item"
      @reflash="reflash"
    ></FormalStudentList>

    <!-- 禁用的弹框 -->
    <RemoveStudentDialog
      ref="del_item"
      v-if="showDelDialog"
      :showDialog="showDelDialog"
      @close="showDelDialog = false"
      :title="delTitle"
      :name="this.name"
      @delItem="delItem"
    ></RemoveStudentDialog>
  </div>
</template>
<script>
// 临加学员
import TempStudentList from "@/views/educationalcenter/classmanager/TempStudentList";
// 正式
import FormalStudentList from "@/views/educationalcenter/classmanager/FormalStudentList";
//移除的弹框
import RemoveStudentDialog from "@/components/project/RemoveStudentDialog";

export default {
  data() {
    return {
      isAddCtrl: true,
      isFormal: false,
      //移出学生姓名
      name: "",
      //标题
      delTitle: "移除学员",
      //是否显示移除弹框的控制值
      showDelDialog: false,
      //查看临加学员
      TempStudentListWidth: "800px",
      //临加学员
      showDialog: false,
      //正式学员
      showformalDialog: false,
      //表格的最大高度
      maxHeight: 0,

      //总的数据条数
      totalSize: "",

      //条数选择的数据
      pageSizeArr: [6, 8, 10],

      //每页显示的条数
      pageSize: 10,

      //当前显示的页码
      currPage: 1,
      //表格数据
      tableData: [],
      //班级ID
      cid: "",
      //学生id
      stuId: "",
      //账号角色
      role: "",
    };
  },
  created() {
    //初始化数据
    this.initData();
  },
  mounted() {
    this.cid = this.item.class_id;

    console.log(this.item);

    if (this.item.type == 0) {
      this.isFormal = true;
    }

    //请求数据
    this.httpData();
  },
  methods: {
    //同步刷新
    reflash() {
      this.$emit("reflash");
      this.httpData();
    },
    //初始化表格数据
    initData() {
      let clientH = document.documentElement.clientHeight;
      this.maxHeight = clientH - 76 - -54 - 20 - 68 - 120;

      //-1游客 0学生  1老师 2校区教务 3校长 4管理员 5超级管理员
      this.role = this.getUserInfo().role;
      console.log(this.role);
    },

    /**
     *  班级列表接口
     */
    httpData() {
      let param = this.getHttpParams();
      param.type = 9;
      param.cid = this.cid;
      param.page = this.currPage - 1;
      param.pagecount = this.pageSize;

      this.$http
        .fetchPost(this.$api.CLASSMANAGER, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let resulte = res.data;
          if (resulte.state == 200) {
            //获取总条数
            this.totalSize = Number.parseInt(resulte.data.totalNum);

            this.tableData = resulte.data.infoList;

            //添加学生按钮隐藏
            if (resulte.allowAdd == 0) {
              this.isAddCtrl = false;
            } else {
              this.isAddCtrl = true;
            }
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },
    //跳转学员列表
    doLookStudent(item) {
      // 获取学生数据
      this.getStudentHttpData(item);
      //跳转到学生档案
    },

    // 获取学生数据
    getStudentHttpData(item) {
      let param = this.getHttpParams();
      param.type = 17;
      param.student_id = item.id;

      this.$http
        .fetchPost(this.$api.StudentManageController, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let resulte = res.data;
          if (resulte.state == 200) {
            this.$router.push({
              path: "/Home/StuRecord/StudentInfo",
              query: { userInfo: JSON.stringify(resulte.data) },
            });
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },
    //移出学员
    delStudent(item) {
      this.name = item.name;
      this.stuId = item.id;
      this.showDelDialog = true;
    },
    //临加学员
    tempStudent() {
      this.showDialog = true;
    },
    //正式学员
    formalStudent() {
      this.showformalDialog = true;
    },
    //移除学员
    async delItem() {
      let param = this.getHttpParams();
      param.type = 10;
      param.cid = this.cid;
      param.stuId = this.stuId;

      let res = await this.$http.fetchPost(this.$api.CLASSMANAGER, param);
      if (res.data.state == 200) {
        this.showSuccessMsg("操作成功");
        //重新刷新数据
        this.httpData();
        //关闭弹窗Loading
        this.$refs.del_item.showCommitLoading = false;
        //关闭弹框
        this.showDelDialog = false;
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
        this.$refs.del_item.showCommitLoading = false;
      }
    },
    //每页显示的条数变化时触发
    handleSizeChange(val) {
      this.pageSize = val;
      // console.log("当前页显示的条数是:", this.pageSize);
      //重新刷新数据
      this.httpData();
    },
    //页面改变时触发
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currPage = val;
      // console.log("当前页码是:", this.currPage);
      //重新刷新数据
      this.httpData();
    },
  },

  computed: {},
  components: { TempStudentList, RemoveStudentDialog, FormalStudentList },
  props: ["item"],
};
</script>
<style lang="scss" scoped>
.course_arrangement {
  height: 100%;

  .temp_student {
    margin-top: -35px;
    margin-bottom: 5px;
    float: right;
  }
  //表格内容部分
  .dv_table_view_content {
    margin: 10px 0;
    height: calc(100vh - 285px);

    .lineHeight {
      margin: -10px 0 15px 0;
    }
    .f_h {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .normal_size {
      font-weight: normal;
      font-size: 12px;
    }
    .tempIcon {
      background-color: #ffffff;
      border-radius: 5px;
      width: 60px;
      padding: 1px 5px;
      color: #f39800;
      border-style: solid;
      border-width: 1px;
      border-color: #f39800;
      margin-right: 2px;

      text-align: center;
    }
    //底部的分页器部分
    .bom_page {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      margin-top: 18px;
      display: flex;
      align-items: center;
      height: 40px;
      justify-content: center;
    }
  }
}
</style>
